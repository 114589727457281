import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import ContentHeader from "../components/ContentHeader";
import { usePrefetch } from "../utils";

const menu = [
    { name: "Products", path: "products" },
    { name: "Avatar", path: "avatar" },
    { name: "Sectors", path: "sectors" }
];

export const AllImages = (props) => {
    const routes = [{ name: "Images" }];
    const path = queryString.parse(props.location.search).path;
    const [current, setCurrent] = useState(0);
    const [products,] = usePrefetch('/api/images/products');
    const [avatar,] = usePrefetch('/api/images/ambassadors');
    const [sectors,] = usePrefetch('/api/images/sectors');

    const images = [products, avatar, sectors];

    useEffect(() => {
        menu.forEach((item, i) => {
            if (item.path === path)
                setCurrent(i);
        })
    }, [path])

    return (
        <div className="content-wrapper">
            <ContentHeader header="Images" subtitle="Views all images" routes={routes} />
            <div className="row">
                <div className="col-12">
                    <div className="card card-outline card-info">
                        <div className="card-header">
                            <h4 className="card-title">Gallery</h4>
                        </div>
                        <div className="card-body">
                            <div>
                                <div className="btn-group w-100 mb-2">
                                    {menu.map((item, i) => (
                                        <button key={i} onClick={() => setCurrent(i)}
                                            className={"btn btn-info" + (current === i ? " active" : "")}>
                                            {item.name}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-container pl-5 row">
                    {images[current].map(name => (
                        <div key={name} className="card"
                            style={{ width: '200px', marginRight: "1.25%", marginLeft: "1.25%" }}
                        >
                            <img
                                className="card-img-top"
                                src={`/ressources/${menu[current].path}/${name}`}
                                alt="Card cap"
                                style={{ maxHeight: "200px" }}
                            />
                            <div className="card-body">
                                <p className="card-text text-center fs-1">{name}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
