import React, { useState, useEffect, Suspense } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Header from './components/Header';
import SideBar from './components/SideBar';
import AccessControl from "./views/AccessControl";
import Footer from './components/Footer';
import { Login } from "./views/Login";
import { Logout } from './views/Logout';
import { Loader } from './components/Loader';
import { ToastContainer } from "react-toastify";
import "./styles/App.css";
import 'react-toastify/dist/ReactToastify.css';

const App = () => {

    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const id = localStorage.NUAT_ADMIN_ID;
        if (id) {
            localStorage.setItem('NUAT_ADMIN_ID', id);
            setLoggedIn(true);
        };
    }, [loggedIn]);


    return (
        <div className="wrapper">
            <BrowserRouter>
                <Header loggedIn={loggedIn} />
                <SideBar loggedIn={loggedIn} />
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route exact path="/" render={() => <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} />
                        <Route exact path="/logout" render={() => <Logout setLoggedIn={setLoggedIn} />} />
                        <Route exact path='/loading' component={Loader} />
                        <AccessControl loggedIn={loggedIn} />
                    </Switch>
                    <Footer loggedIn={loggedIn} />
                    <ToastContainer />
                </Suspense>
            </BrowserRouter>
        </div>
    )
};

export default App;