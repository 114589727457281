import './style.css';

import React, { useEffect, useState } from 'react'
import { niceSend, usePrefetch, useSave } from '../../utils';

import SelectData from '../SelectData';

export const defaultFields = {
    id: 0,
    name: "",
    video: "",
    image: "",
    description: "",
    sectors: []
};

const BasicData = ({ data, images, sendNewId }) => {
    const [state, setState] = useState(defaultFields);
    const [sectors,] = usePrefetch('/api/sectors');
    const [selectedSectors, setSelectedSectors] = useState({});
    const [productSector, setProductSector] = useState([]);

    const updateData = async () => {
        const { error, newId } = await niceSend(
            '/api/products',
            'POST',
            { ...state, sectors: selectedSectors }
        );
        sendNewId(newId);
        return { error };
    };
    const [trigger,] = useSave(updateData);

    useEffect(() => {
        setState({...state, ...data});
        setProductSector(data.sectors || []);
    }, [data]);

    const onChange = e => setState({
        ...state,
        [e.target.name]: e.target.value
    });

    return (
        <div className="card card-info">
            <div className="card-header">
                <h3 className="card-title">Basic Data</h3>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="productName">Name</label>
                    <input type="text" className="form-control" id="productName"
                        name="name" value={state.name} onChange={onChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="productVideo">Video Link</label>
                    <input type="text" className="form-control" id="productVideo"
                        name="video" value={state.video} onChange={onChange} />
                </div>
                <div className="form-group">
                    <label>Image from default folder</label>
                    <select className="form-control" name="image" value={state.image} onChange={onChange}>
                        <option></option>
                        {images.map((image, i) => <option key={i}>{image}</option>)}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="productDescription">Description</label>
                    <textarea rows="5" className="form-control" id="productDescription"
                        name="description" value={state.description} onChange={onChange} />
                </div>
                <SelectData 
                    title="Associated sector"
                    selectText="Select associated sectors"
                    allData={sectors}
                    initialState={productSector}
                    callback={(obj) => setSelectedSectors(obj)}
                />
            </div>
            <div className="card-footer">
                <button type="submit" className="btn btn-info" onClick={() => trigger()}>Save</button>
            </div>
        </div>

    )
}

export default BasicData;
