import React, { useEffect, useState } from 'react';
import { paginate, LIMIT, range } from '../utils';
import { DeleteButton } from '../Toaster';

const Table = (props) => {
    const {
        header,
        footer,
        data,
        limit,
        editItem,
        deleteItem,
        tableName
    } = props;
    const [pagination, setPagination] = useState({ allPages: [], nbPages: 0 });
    const [perPage, setPerPage] = useState(limit);
    const [current, setCurrent] = useState(1);
    const [columns, setColumns] = useState([]);

    // deal with too many pages
    const pagesArray = () => {
        const n = pagination.nbPages;
        if (n <= 7) {
            return range(1, n + 1);
        } else {
            let pages = range(1, 8);
            pages[0] = 1;
            pages[2] = Math.min(n - 4, Math.max(3, current - 1));
            pages[3] = Math.min(n - 3, Math.max(4, current));
            pages[4] = Math.min(n - 2, Math.max(5, current + 1));
            pages[5] = 0;
            pages[6] = n;

            if (pages[2] > 3) pages[1] = 0;
            if (pages[4] === n - 2) pages[5] = n - 1;
            return pages;
        }
    };

    useEffect(() => {
        setCurrent(Math.max(
            1,
            Math.min(current, pagination.nbPages)
        ));
    }, [current, pagination]);

    useEffect(() => {
        setPagination(paginate(data, perPage));
        if (data.length) {
            setColumns(Object.keys(data[0]));
        }
    }, [data, perPage]);

    const addNew = () => {
        props.editItem(0);
    };

    const previousClick = () => {
        if (current > 1) setCurrent(current - 1);
    };
    const nextClick = () => {
        if (current < pagination.nbPages)
            setCurrent(current + 1);
    };

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="card card-outline card-primary">
                    <div className="card-header with-border">
                        <h3 className="card-title">{header}</h3>
                    </div>
                    <div className="card-body">
                        {(tableName !== "Ambassadors") && (
                            <div className="p-2">
                                <button className="btn btn-outline-primary" onClick={addNew}>
                                    <i className="fa fa-plus"></i> Add new
                                </button>
                            </div>
                        )}
                        <div className="form-group">
                            <label htmlFor="perpage">Nb entries per page</label>
                            <select
                                type="text"
                                className="custom-select rounded-3"
                                style={{width: "75px", marginLeft: "10px"}}
                                id="perpage"
                                name="perpage"
                                value={perPage}
                                onChange={(e) => setPerPage(Number(e.target.value))} 
                            >
                                <option>10</option>
                                <option>15</option>
                                <option>25</option>
                                <option>50</option>
                            </select>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            {columns.map(name => <th key={name}>{name}</th>)}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(pagination.allPages.length && current <= pagination.nbPages) ?
                                            pagination.allPages[current - 1].map((obj, i) => (
                                                <tr key={i}>
                                                    {Object.entries(obj).map(([key, value]) => (
                                                        <td key={key}>{value}</td>
                                                    ))}
                                                    <td>
                                                        <div className="btn-group btn-group-sm">
                                                            <button className="btn btn-default" onClick={() => editItem(obj.id)}>
                                                                <i className="fa fa-edit"></i>
                                                            </button>
                                                            <DeleteButton id={obj.id} name={obj.name}
                                                                deleteItem={() => deleteItem(obj.id)} tableName={tableName} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : null}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            {columns.map(name => <th key={name}>{name}</th>)}
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info">
                                    Showing {(current - 1) * (perPage || LIMIT) + 1}&nbsp;
                                    to {Math.min(current * (perPage || LIMIT), data.length)} of {data.length} entries
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-7">
                                <div className="dataTables_paginate paging_simple_numbers">
                                    <ul className="pagination">
                                        <li className={"paginate_button page-item previous" + (current === 1 ? " disabled" : "")}
                                            onClick={previousClick}>
                                            <button className="page-link">Previous</button>
                                        </li>
                                        {pagesArray().map((pageNumber, i) => (
                                            <li key={i} className={"paginate_button page-item" + (pageNumber === current ? " active" : "")}>
                                                <button className="page-link" onClick={pageNumber ? () => setCurrent(pageNumber) : () => null}>
                                                    {pageNumber ? pageNumber : '...'}
                                                </button>
                                            </li>
                                        ))}
                                        <li className={"paginate_button page-item next" + (current === pagination.nbPages ? " disabled" : "")}
                                            onClick={nextClick}>
                                            <button className="page-link">Next</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">{footer}</div>
                </div>
            </div>
        </section>
    );
};

export default Table;