import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import * as _ from "lodash";
import ContentHeader from '../components/ContentHeader';
import BasicData, { defaultFields } from '../components/Sectors/BasicData';
import { usePrefetch } from "../utils";
import Images from '../components/Images';

export const EditSector = (props) => {
    const routes = [
        { name: "Sectors", link: "/sectors" },
        { name: "EditSector" }
    ];
    const id = Number(queryString.parse(props.location.search).id);
    const [basic, setBasic] = useState(defaultFields);
    const [data,] = usePrefetch(`/api/sectors/${id}`);
    const [images, updateImages] = usePrefetch('/api/images/sectors');

    useEffect(() => {
        setBasic(
            _.isEmpty(data.basicData)
                ? defaultFields
                : data.basicData
        );
    }, [data]);

    const header = (basic.id === 0 ? "Add New" : "Edit") + " Sector ";

    return (
        <div className="content-wrapper">
            <ContentHeader header={header} subtitle={basic.name} routes={routes} />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <BasicData data={basic} images={images} />
                        </div>
                        <div className="col-sm-6">
                            <Images data={images} path="sectors" updateCallback={updateImages} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
