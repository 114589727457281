import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import * as _ from 'lodash';
import ContentHeader from '../components/ContentHeader';
import BasicData, { defaultFields } from '../components/Products/BasicData';
// import Process from '../components/Products/Process';
import { usePrefetch } from "../utils";
import Images from '../components/Images';

export const EditProduct = (props) => {
    const routes = [
        { name: "Products", link: "/products" },
        { name: "EditProduct" }
    ];
    const id = Number(queryString.parse(props.location.search).id);
    const [basic, setBasic] = useState(defaultFields);
    const [data,] = usePrefetch(`/api/products/${id}`);
    const [images, updateImages] = usePrefetch('/api/images/products');
    const [newId, setNewId] = useState(undefined);

    useEffect(() => {
        setBasic(
            !_.isEmpty(data.basicData)
                ? { ...data.basicData, sectors: data.sectors }
                : defaultFields
        );
    }, [data, id]);

    const header = (basic.id === 0 ? "Add New" : "Edit") + " Product ";

    return (
        <div className="content-wrapper">
            <ContentHeader header={header} subtitle={basic.name} routes={routes} />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <BasicData data={basic} images={images} sendNewId={setNewId} />
                        </div>
                        <div className="col-sm-6">
                            <Images data={images} path="products" updateCallback={updateImages} />
                            {/* <Process productId={id} data={data.process || []} newId={newId} /> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
