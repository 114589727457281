import * as _ from 'lodash';

import BasicData, { defaultFields } from '../components/Jobs/BasicData';
import React, { useEffect, useState } from 'react';

import ContentHeader from '../components/ContentHeader';
import queryString from 'query-string';
import { usePrefetch } from "../utils";

export const EditJob = (props) => {
    const routes = [{ name: "Jobs", link: "/jobs" }, { name: "EditJob" }];
    const id = Number(queryString.parse(props.location.search).id);
    const [basic, setBasic] = useState(defaultFields);
    const [data,] = usePrefetch(`/api/jobs/${id}`);

    useEffect(() => {
        setBasic(
            !_.isEmpty(data.basicData)
                ? { ...data.basicData, sectors: data.sectors }
                : defaultFields
        );
    }, [data, id]);

    const header = (basic.id === 0 ? "Add New" : "Edit") + " Job ";

    return (
        <div className="content-wrapper">
            <ContentHeader header={header} subtitle={basic.name} routes={routes} />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <BasicData data={basic} />
                        </div>
                        <div className="col-sm-6">
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
