import React, { useEffect, useState } from 'react';

const getSelectMap = (data) => {
    const selectMap = {};
    data.forEach(item => {
        selectMap[item.id] = false;
    });
    return selectMap;
};

const SelectData = ({ allData, initialState, title, selectText, callback }) => {
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState(getSelectMap(allData));

    const onChecked = (e, id) => {
        setSelectedData({
            ...selectedData,
            [id]: !selectedData[id]
        });
    };

    useEffect(() => {
        callback(selectedData);
        // eslint-disable-next-line
    }, [selectedData]);

    useEffect(() => {
        const selectMap = { ...selectedData };
        initialState.forEach(item => {
            selectMap[item.id] = true;
        });
        setSelectedData(selectMap);
        // eslint-disable-next-line
    }, [initialState]);

    return (
        <div className="form-group">
            <label>{title}</label>
            <div id="list1"
                className={"dropdown-check-list " + (show ? " visible" : "")}
            >
                <span className="anchor" onClick={() => setShow(!show)}>
                    {selectText || ""}
                </span>
                <ul className="items">
                    {allData.map(({ id, name }) => (
                        <li key={id}>
                            <input
                                type="checkbox"
                                checked={selectedData[id]}
                                onChange={(e) => onChecked(e, id)}
                            />
                            {name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SelectData;