import React, { useState } from "react";
import jwt_decode from 'jwt-decode';
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { niceSend } from "../utils";
import { makeToast } from "../Toaster";

export const Login = ({ loggedIn, setLoggedIn }) => {
    const [user, setUser] = useState({ username: "", password: "" });

    const onChange = (e) => setUser({
        ...user,
        [e.target.name]: e.target.value
    });

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const { token, error } = await niceSend('/api/admin/login', 'POST', user);
            if (!error) {
                const decoded = jwt_decode(token);
                localStorage.setItem('NUAT_ADMIN_ID', decoded.id);
                setLoggedIn(true);
            } else makeToast("error", error);
        } catch (err) {
            console.error("Can't log in");
            makeToast("error", "Server error");
        }
    };

    if (loggedIn) {
        return <Redirect to="/dashboard" />;
    };

    return (
        <div>
            <Helmet>
                <body class="hold-transition login-page" />
            </Helmet>
            <div className="login-box">
                <div className="login-logo">
                    <b>Admin</b>NUAT
                    Administration du site <b>Nos usines à talents</b>
                </div>
                <div className="login-box-body">
                    <p className="login-box-msg">Veuillez vous connecter !</p>

                    <form onSubmit={onSubmit}>
                        <div className="form-group has-feedback">
                            <input
                                name="username"
                                value={user.username}
                                onChange={onChange}
                                type="text"
                                className="form-control"
                                placeholder="Username"
                            />
                        </div>
                        <div className="form-group has-feedback">
                            <input name="password"
                                value={user.password}
                                onChange={onChange}
                                type="password"
                                className="form-control"
                                placeholder="Password"
                            />
                        </div>
                        <div className="row">
                            <div className="col-xs-4">
                                <button onClick={onSubmit} className="btn btn-primary btn-block btn-flat">
                                    Sign In
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};