import React, { useEffect, useState } from 'react';
import { makeToast, newUserAdded } from '../../Toaster';
import { niceSend, usePrefetch, useSave } from '../../utils';

import jwt_decode from 'jwt-decode';

export const defaultFields = {
    id: 0,
    name: "",
    surname: "",
    email: "",
    image: "",
    status: 0,
    description: "",
};

const BasicData = ({ data, images, path }) => {
    const [state, setState] = useState(defaultFields);
    const [access,] = usePrefetch('/api/admin/accessStatus');

    useEffect(() => setState({...state, ...data}), [data]);
    const updateData = async () => {
        // eslint-disable-next-line
        const method = state.id == 0 ? "POST" : "PATCH";
        // eslint-disable-next-line
        state.generate = state.id == 0 ? true : undefined;
        const res = await niceSend(`/api/users/${state.id}`, method, state);
        if (res.pwdToken) {
            let decoded = jwt_decode(res.pwdToken);
            let status = access.accessName[state.status]
            newUserAdded(state.email, decoded.pwd, status);
        }
        const updateStatus = { 
            status: state.status, 
            userId: state.generate ? res.id : state.id 
        };
        await niceSend('/api/ambassadors/0/status', "POST", updateStatus);
        
        return { error: res.error, freeze: true };
    };
    const [trigger,] = useSave(updateData);

    const onChange = e => setState({ ...state, [e.target.name]: e.target.value });
    const isValid = () => {
        let valid = true;
        ["name", "surname", "email"].forEach(key => {
            if (!state[key] || state[key] === '') {
                valid = false;
            }
        });
        return valid;
    };

    const save = () => {
        if (!isValid()) {
            makeToast("error", "Name, surname and email required !");
        } else {
            trigger();
        }
    };

    return (
        <div className="card card-info">
            <div className="card-header">
                <h3 className="card-title">Basic Data</h3>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="userName">Name</label>
                    <input type="text" className="form-control" id="userName"
                        name="name" value={state.name} onChange={onChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="userSurname">Surname</label>
                    <input type="text" className="form-control" id="userSurname"
                        name="surname" value={state.surname} onChange={onChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="userEmail">Email</label>
                    <input type="text" className="form-control" id="userEmail"
                        name="email" value={state.email} onChange={onChange} />
                </div>
                <div className="form-group">
                    <label>Status</label>
                    <select className="form-control" name="status" value={state.status} onChange={onChange}>
                        {Object.entries(access.accessStatus).map(([name, value]) => (
                            <option value={value} key={value}>{name}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label>Image from default folder</label>
                    <select className="form-control" name="image" value={state.image} onChange={onChange}>
                        {images.map((image, i) => <option key={i}>{image}</option>)}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="userDescription">Description</label>
                    <textarea rows="5" className="form-control" id="userDescription"
                        name="description" value={state.description} onChange={onChange} />
                </div>
            </div>
            <div className="card-footer">
                <button type="submit" className="btn btn-info" onClick={save}>Save</button>
            </div>
        </div>

    )
}

export default BasicData
