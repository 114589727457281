import React, { useEffect, useState } from 'react';
import ContentHeader from "../components/ContentHeader";
import Table from '../components/Table';
import { usePrefetch, niceSend } from "../utils";

export const Sectors = (props) => {
    const routes = [{ name: "Sectors" }];
    const [basicData, setBasicData] = useState([]);
    const [data, updateData] = usePrefetch('/api/sectors');

    useEffect(() => {
        setBasicData(data);
    }, [data]);

    const editItem = i => props.history.push(`/editsector?id=${i}`);
    const deleteItem = async (id) => {
        try {
            const { success } = await niceSend(`/api/sectors/${id}`, "DELETE");
            updateData();
            return success;
        } catch (err) {
            return false;
        }
    };

    return (
        <div className="content-wrapper">
            <ContentHeader header="Sectors" routes={routes} />
            <Table
                header="Basic sectors info"
                data={basicData}
                editItem={editItem}
                deleteItem={deleteItem}
                tableName="Sectors"
                footer=""
            />
        </div>
    )
};