import React, { useState, useEffect } from 'react'
import { niceSend, useSave } from '../../utils';
import { makeToast } from '../../Toaster';

export const defaultFields = {
    id: 0,
    name: "",
    surname: "",
    image: "",
    video: "",
    description: "",
    del: 0,
};

const BasicData = ({ data, images }) => {
    const [state, setState] = useState(defaultFields);
    const updateData = async () => {
        const { error } = await niceSend('/api/ambassadors', 'POST', state);
        return { error };
    };
    const [trigger, stop] = useSave(updateData);

    useEffect(() => setState(data), [data]);

    const onChange = e => setState({
        ...state,
        [e.target.name]: e.target.value
    });

    const save = () => {
        const { name, surname } = state;
        state.del = Number(state.del);
        if (!name || !surname) {
            makeToast("error", "Name and surname required !");
            stop();
        } else {
            trigger();
        }
    };

    return (
        <div className="card card-info">
            <div className="card-header">
                <h3 className="card-title">Basic Data</h3>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="delAmbassador">Delete</label>
                    <select className="form-control" name="del" value={state.del} onChange={onChange}>
                        <option value={0}>NO</option>
                        <option value={1}>YES</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="ambassadorName">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="ambassadorName"
                        name="name"
                        value={state.name}
                        onChange={onChange}
                        // eslint-disable-next-line
                        disabled={state.del == 1}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="ambassadorSurname">Surname</label>
                    <input
                        type="text"
                        className="form-control"
                        id="ambassadorSurname"
                        name="surname"
                        value={state.surname}
                        onChange={onChange}
                        // eslint-disable-next-line
                        disabled={state.del == 1}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="ambassadorVideo">Video Link</label>
                    <input
                        type="text"
                        className="form-control"
                        id="ambassadorVideo"
                        name="video"
                        value={state.video}
                        onChange={onChange}
                        // eslint-disable-next-line
                        disabled={state.del == 1}
                    />
                </div>
                <div className="form-group">
                    <label>Image from default folder</label>
                    <select
                        className="form-control"
                        name="image"
                        value={state.image}
                        onChange={onChange}
                        // eslint-disable-next-line
                        disabled={state.del == 1}
                    >
                        {images.map((image, i) => <option key={i}>{image}</option>)}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="ambassadorDescription">Description</label>
                    <textarea
                        rows="5"
                        className="form-control"
                        id="ambassadorDescription"
                        name="description"
                        value={state.description}
                        onChange={onChange}
                        // eslint-disable-next-line
                        disabled={state.del == 1}
                    />
                </div>
            </div>
            <div className="card-footer">
                <button type="submit" className="btn btn-info" onClick={save}>Save</button>
            </div>
        </div>
    );
}

export default BasicData
