import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import * as _ from 'lodash';
import { usePrefetch } from '../utils';
import ContentHeader from '../components/ContentHeader';
import BasicData, { defaultFields } from '../components/Ambassadors/BasicData';
import Images from '../components/Images';
import OtherData from '../components/Ambassadors/Others';

// TODO: change this later. Should be able to modify
const frozenFields = [
    "job",
    "factoryName",
    "firm",
    "city",
    "postcode",
    "longitude",
    "latitude"
];

export const EditAmbassador = (props) => {
    const routes = [
        { name: "Ambassadors", link: "/ambassadors" },
        { name: "EditAmbassador" }
    ];
    const id = Number(queryString.parse(props.location.search).id);
    const [basic, setBasic] = useState(defaultFields);
    const [data,] = usePrefetch(`/api/admin/ambassadors/${id}`);
    const [images, updateImages] = usePrefetch('/api/images/ambassadors');

    useEffect(() => {
        if (!_.isEmpty(data.basicData)) {
            const temp = { ...data.basicData };
            frozenFields.forEach(key => delete temp[key]);
            setBasic(temp);
        }
    }, [data]);

    const header = (basic.id === 0 ? "Add New" : "Edit") + " Ambassador ";

    return (
        <div className="content-wrapper">
            <ContentHeader header={header} subtitle={basic.name} routes={routes} />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <BasicData data={basic} images={images} />
                        </div>
                        <div className="col-sm-6">
                            <Images data={images} path="avatar" updateCallback={updateImages} />
                            {/* <Products data={data.products || []} /> */}
                            <OtherData data={data} id={id} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
