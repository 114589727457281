import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import * as All from "./";

const AccessControl = ({ loggedIn }) => {
    const [views, setViews] = useState([]);

    useEffect(() => {
        const allViews = Object.entries(All).map(([key, value]) => ({
            path: `/${key.toLowerCase()}`, 
            component: loggedIn ? value : () => null
        }));
        setViews(allViews);
    }, [loggedIn]);

    return (
        <React.Fragment>
            {views.map((view) => (
                <Route key={view.path} path={view.path} component={view.component} />
            ))}
        </React.Fragment>
    );
}

export default AccessControl;
