import React, { useEffect, useState } from 'react';
import { niceSend, useSave } from '../../utils';

export const defaultFields = {
    id: 0,
    name: "",
    video: "",
    image: "",
    short_desc: "",
    description: "",
};


const BasicData = ({ data, images }) => {
    const [state, setState] = useState(defaultFields);
    useEffect(() => setState({...state, ...data}), [data]);
    const updateData = async () => {
        const { error } = await niceSend('/api/sectors', 'POST', state);
        return { error };
    };
    const [trigger,] = useSave(updateData);

    const onChange = e => setState({
        ...state,
        [e.target.name]: e.target.value
    });

    return (
        <div className="card card-info">
            <div className="card-header">
                <h3 className="card-title">Basic Data</h3>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="sectorName">Name</label>
                    <input type="text" className="form-control" id="sectorName"
                        name="name" value={state.name} onChange={onChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="sectorVideo">Video Link</label>
                    <input type="text" className="form-control" id="sectorVideo"
                        name="video" value={state.video} onChange={onChange} />
                </div>
                <div className="form-group">
                    <label>Image from default folder</label>
                    <select className="form-control" name="image" value={state.image} onChange={onChange}>
                        <option></option>
                        {images.map((image, i) => <option key={i}>{image}</option>)}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="sectorDescription">Short Description</label>
                    <textarea rows="3" className="form-control" id="sectorShortDesc"
                        name="short_desc" value={state.short_desc} onChange={onChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="sectorDescription">Description</label>
                    <textarea rows="5" className="form-control" id="sectorDescription"
                        name="description" value={state.description} onChange={onChange} />
                </div>
            </div>
            <div className="card-footer">
                <button type="submit" className="btn btn-info" onClick={() => trigger()}>Save</button>
            </div>
        </div>

    )
}

export default BasicData;
