import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

export const Logout = (props) => {
    let [loggedIn,] = useState(false);

    useEffect(() => {
        localStorage.removeItem('NUAT_ADMIN_ID');
        props.setLoggedIn(loggedIn);
    }, [loggedIn, props])

    return <Redirect to="/" />
};