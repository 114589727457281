import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { paginate, niceSend } from "../utils";
import { DeleteButton, viewImage, makeToast } from "../Toaster";

const Images = ({ data, limit, path, updateCallback }) => {
    const history = useHistory();
    const [pagination, setPagination] = useState({ allPages: [], nbPages: 0 });
    const [current, setCurrent] = useState(1);
    const [filename, setFilename] = useState('Choose file');

    useEffect(() => {
        setPagination(paginate(data, limit));
        setCurrent(1);
    }, [data, limit]);

    const deleteImage = async (name) => {
        const filePath = `${path}/${name}`;
        const { error } = await niceSend('/api/images/delete', 'DELETE', { filePath });
        await updateCallback();
        return error ? false : true;
    };

    const previousClick = () => {
        if (current > 1) setCurrent(current - 1);
    };
    const nextClick = () => {
        if (current < pagination.nbPages) setCurrent(current + 1);
    };

    return (
        <div className="card card-warning">
            <div className="card-header">
                <h3 className="card-title">Images from default folder</h3>
            </div>
            <div className="card-body p-0">
                <table className="table table-bordered p-0">
                    <thead>
                        <tr>
                            <th style={{ width: 10 }}>#</th>
                            <th>Name</th>
                            <th style={{ width: 10 }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pagination.allPages.length
                                ? pagination.allPages[current - 1].map((img, i) => (
                                    <tr key={i}>
                                        <td>{(current - 1) * limit + i + 1}</td>
                                        <td>{img}</td>
                                        <td>
                                            <div className="text-center">
                                                <div className="btn-group">
                                                    <button
                                                        className="btn btn-default"
                                                        onClick={() => viewImage(path, img, updateCallback)}
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </button>
                                                    <DeleteButton
                                                        name={img}
                                                        tableName="Image folder"
                                                        deleteItem={deleteImage}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
                <div className="card-footer clearfix">
                    <ImageFromComputer filename={filename} setFilename={setFilename} path={path} updateCallback={updateCallback} />
                    <div className="btn-group">
                        <button className="btn btn-warning" onClick={() => history.push(`/allimages?path=${path}`)}>
                            View all Images
                        </button>
                    </div>
                    <ul className="pagination pagination-sm m-0 float-right">
                        <li className="page-item" onClick={previousClick}>
                            <button className="page-link" to="#">«</button>
                        </li>
                        {[...Array(pagination.nbPages)].map((_, i) => (
                            <li key={i} className="page-item" onClick={() => setCurrent(i + 1)}>
                                <button to="#" className="page-link">{i + 1}</button>
                            </li>
                        ))}
                        <li className="page-item" onClick={nextClick}>
                            <button className="page-link">»</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

Images.defaultProps = { limit: 5 };

const ImageFromComputer = ({ path, filename, setFilename, updateCallback }) => {
    const [imgCollection, setImgCollection] = useState([]);

    const fileChange = (e) => {
        let nameString = "", files = e.target.files;
        for (let key of Object.keys(e.target.files)) {
            let file = e.target.files[key];
            if (/^(image\/)/.test(file.type)) {
                nameString = nameString + file.name + " | ";
            } else {
                setFilename('Choose file');
                makeToast("error", "File should be image");
                files = [];
                break;
            }
        };
        setFilename(nameString.slice(0, nameString.length - 2));
        setImgCollection(files);
    };
    const uploadfile = async () => {
        const formData = new FormData();
        for (let key of Object.keys(imgCollection)) {
            formData.append('imgCollection', imgCollection[key]);
        };
        formData.append('len', imgCollection.length);
        formData.append('path', path);
        const { error } = await niceSend('/api/images/upload', 'POST', formData, true);
        const icon = error ? "error" : "success";
        const title = error ? error : "Saved Succesfully";
        await updateCallback();
        setImgCollection([]);
        setFilename('Choose file');
        makeToast(icon, title);
    };

    return (
        <div className="form-group">
            <label htmlFor="exampleInputFile">Add image from your computer</label>
            <div className="input-group">
                <div className="custom-file" onChange={fileChange}>
                    <input type="file" className="custom-file-input" id="exampleInputFile" multiple />
                    <label className="custom-file-label" htmlFor="exampleInputFile">{filename}</label>
                </div>
                <div className="input-group-append">
                    <button className="btn btn-warning" onClick={uploadfile}>Upload</button>
                </div>
            </div>
            {/* <div className="mt-2">
                <div className="progress progress-xs progress-striped">
                    <div className="progress-bar bg-warning" style={{ width: "25%" }}></div>
                </div>
            </div> */}
        </div>
    )
}

export default Images;
