import React from 'react';
import { Link } from 'react-router-dom';
// import { FaHardHat } from 'react-icons/fa';

export const DashBox = ({ count, title, icon, Icon, link, bg, percent }) => {

    return (
        <div className="col-lg-3 col-xs-6">
            <div className={"small-box bg-" + bg}>
                <div className="inner">
                    <h3>{count}
                        {percent && <sup style={{fontSize: '20px'}}>%</sup>}
                    </h3>
                    <p>{title}</p>
                </div>
                <div className="icon">
                    {icon ? <i className={icon}></i> : <Icon />}
                </div>
                <Link to={link} className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></Link>
            </div>
        </div>
    );
};

export const DashBoxes = ({ count }) => {

    return (
        <section className="content">
            <div className="row">
                <DashBox count={count.products} title="Products" bg="info" icon="fa fa-shopping-cart" link="/products" />
                <DashBox count={count.ambassadors} title="Ambassadors" bg="green" icon="fa fa-user" link="/ambassadors"/>
                <DashBox count={count.sectors} title="Sectors" bg="red" icon="fa fa-pie-chart" link="/sectors" />
                <DashBox count={count.jobs} title="Jobs" bg="purple" icon="fas fa-hard-hat" link="/jobs" />
                <DashBox count="25" title="Enterprises" bg="maroon" icon="fa fa-globe" link="#" />
                <DashBox count={count.users} title="User registrations" bg="yellow" icon="fa fa-user-plus" link="/users" />
                <DashBox count="Mail" title="Users feedback" bg="teal" icon="fa fa-envelope" link="/mailbox" />
                <DashBox count="API" title="Documentation" bg="secondary" icon="fa fa-book" link="/apidocs" />
            </div>
        </section>
    );
};