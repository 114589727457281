import React from 'react';
import { Link } from "react-router-dom";

const SideBar = ({ loggedIn }) => {

    return (
        <React.Fragment>
            {loggedIn &&
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    <Link to="/dashboard" className="brand-link">
                        <img src="/ressources/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                        <span className="brand-text font-weight-light">NuatAdmin</span>
                    </Link>
                    <div className="sidebar">
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex" >
                            <div className="image">
                                <img src="/ressources/user2-160x160.jpg" className="img-circle elevation-2" alt="User" />
                            </div>
                            <div className="info">
                                <Link to="/dashboard" className="d-block">Admin Default</Link>
                            </div>
                        </div>

                        <nav className="mt-2" >
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
                                <li className="nav-item">
                                    <Link to="/dashboard" className="nav-link">
                                        <i className="nav-icon fas fa-tachometer-alt" />
                                        <p>Dashboard</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/ambassadors" className="nav-link">
                                        <i className="nav-icon fas fa-user" />
                                        <p>Ambassadors</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/products" className="nav-link">
                                        <i className="nav-icon fas fa-shopping-cart" />
                                        <p>Products</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/sectors" className="nav-link">
                                        <i className="nav-icon fas fa-chart-pie" />
                                        <p>Sectors</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/jobs" className="nav-link">
                                        <i className="nav-icon fas fa-hard-hat" />
                                        <p>Jobs</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/users" className="nav-link">
                                        <i className="nav-icon fas fa-user-plus" />
                                        <p>Users</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/mailbox" className="nav-link">
                                        <i className="nav-icon fas fa-envelope" />
                                        <p>Mailbox</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/apidocs" className="nav-link">
                                        <i className="nav-icon fas fa-book" />
                                        <p>Documentation</p>
                                    </Link>
                                </li>
                            </ul>
                        </nav >
                    </div>
                </aside>
            }
        </React.Fragment>
    )
}

export default SideBar;