import React, { useState } from 'react';
import ContentHeader from "../components/ContentHeader";
import Messages from '../components/Messenger/Messages';
import { SideBar } from '../components/Messenger/SideBar';
import { usePrefetch } from '../utils';

/* state values:
        - 0 : inbox
        - 1 : compose
        - 2 : read email
        - 3 : sent
        - 4 : drafts
        - 5 : trash
*/

export const MailBox = () => {
    const [state, setState] = useState(0);
    const routes = [{ name: "MailBox" }];
    const id = localStorage.NUAT_ADMIN_ID;
    // const [messages,] = usePrefetch(`/api/messages/admin/${id}`);

    return (
        <div className="content-wrapper">
            <ContentHeader header="Inbox" subtitle="Users feedbacks" routes={routes} />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <SideBar inbox={state === 0} nbNewMessages={12} changeState={setState} />
                        {/* <Messages state={state} changeState={setState} data={messages} /> */}
                    </div>
                </div>
            </section>
        </div>
    );
};
