import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ loggedIn }) => {

    return (
        <React.Fragment>
            {loggedIn &&
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" data-widget="pushmenu" to="#" role="button">
                                <i className="fas fa-bars" />
                            </Link>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <Link to="/dashboard" className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <Link to="/logout" className="nav-link">Logout</Link>
                        </li>
                    </ul>
                </nav>
            }
        </React.Fragment>
    )
};

export default Header;