import React from 'react';

export default function Footer({ loggedIn }) {
    return (
        <React.Fragment>
            {loggedIn && (
                <footer className="main-footer">
                    <strong>Copyright © <a href="https://nosusinesatalents.fr">Nos Usines à Talents</a>.</strong>
                    &nbsp; All rights reserved.
                    <div className="float-right d-none d-sm-inline-block">
                        <b>Version</b> 1.1.0-rc
                    </div>
                </footer>
            )}
        </React.Fragment>
    )
};
