import React, { useEffect, useState } from 'react';
import ContentHeader from "../components/ContentHeader";
import Table from '../components/Table';
import { usePrefetch, niceSend } from "../utils";

export const Jobs = (props) => {
    const routes = [{ name: "Sectors" }];
    const [basicData, setBasicData] = useState([]);
    const [data, updateData] = usePrefetch('/api/jobs');

    const editItem = i => props.history.push(`/editjob?id=${i}`);
    const deleteItem = async (id) => {
        try {
            const { success } = await niceSend(`/api/jobs/${id}`, "DELETE");
            updateData();
            return success;
        } catch (err) {
            return false;
        }
    };
    useEffect(() => {
        setBasicData(data);
    }, [data]);

    return (
        <div className="content-wrapper">
            <ContentHeader header="Jobs" subtitle="All jobs with info" routes={routes} />
            <Table
                header="Basic jobs info"
                tableName="Jobs"
                data={basicData}
                editItem={editItem}
                deleteItem={deleteItem}
                footer=""
            />
        </div>
    )
};