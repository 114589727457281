import React from 'react';

export const SideBar = ({ inbox, nbNewMessages, changeState }) => {
    const style = {cursor: "pointer"};
    return (
        <div className="col-md-3">
            {inbox ? (
                <div className="btn btn-info btn-block mb-3" onClick={() => changeState(1)}>
                    <i className="fas fa-edit" /> Compose
                </div>
            ) : (
                <div className="btn btn-info btn-block mb-3"  onClick={() => changeState(0)}>
                    <i className="fas fa-chevron-left pr-2" /> Back to Inbox
                </div>
            )}
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Folders</h3>
                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fas fa-minus" />
                        </button>
                    </div>
                </div>
                <div className="card-body p-0">
                    <ul className="nav nav-pills flex-column">
                        <li className="nav-item active">
                            <div className="nav-link" style={style} onClick={() => changeState(0)}>
                                <i className="fas fa-inbox" /> Inbox
                                <span className="badge bg-info float-right">{nbNewMessages}</span>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link" style={style} onClick={() => changeState(3)}>
                                <i className="far fa-envelope" /> Sent
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link" style={style} onClick={() => changeState(4)}>
                                <i className="far fa-file-alt" /> Drafts
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link" style={style} onClick={() => changeState(5)}>
                                <i className="far fa-trash-alt" /> Trash
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
