import React from 'react'
import BuildTree from '../components/BuildTree';
import ContentHeader from "../components/ContentHeader";
import { serverStructure } from '../static.data';

export const ApiDocs = () => {
    const routes = [{ name: "Documentation" }];
    return (
        <div className="content-wrapper">
            <ContentHeader header="Documentation" subtitle="All useful information" routes={routes} />
            <div className="px-4 py-2">
                <div className="content-header">
                    <h1>Introduction</h1>
                </div>
                <div className="content fs-3 px-2">
                    <p>
                        You can get the source code of the whole website from here: <a href="https://github.com/chrichri17/nuat-react-app.git">source code</a>.
                        <br />
                        There is also a tuto to setup a local production environment or deploy the website.
                        We'll focus on the structure of the application in this document.
                    </p>
                    <div className="card card-outline card-info">
                        <div className="card-header">
                            <h1 className="card-title">Database modelisation</h1>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus" />
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div>
                                <img src="/ressources/db_diagram.png" alt="database diagram" style={{width: "95%"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="card card-outline card-success">
                        <div className="card-header">
                            <h1 className="card-title">API docs</h1>
                        </div>
                        <div className="card-body">
                            API URL: <span className="link">http://localhost:5000/api</span> (in development) or <span className="link">https://nosusinesatalents.fr/api</span> (in production).
                            <p>The server code is well commented. However, here are some tips and advices: </p>
                            <h5 className="mt-2">Folder structure</h5>
                            
                            <span style={{color: "blue", fontSize: "400"}}>(click on server to display)</span>
                            <BuildTree structure={serverStructure} />

                            <div className="pt-3">
                                The above folder tree contains the essential server files. 
                                <ul>
                                    <li>
                                        <code>server.js</code>: main file of the server; starting the connection to
                                        the database and the server on port 5000
                                    </li>
                                    <li>
                                        <code>config.js</code>: returns an object containing the information needed
                                        to connect to the database
                                    </li>
                                    <li>
                                        <code>db.js</code>: contains all the useful functions to fetch data from the database
                                    </li>
                                    <li>
                                        <code>utils.js</code>: contains some helpers functions
                                    </li>
                                    <li>
                                        <code>knexfile.js</code>: contains all useful information for <code>knexJS</code>
                                    </li>
                                    <li>
                                        <code>ressources/</code>: contains all static files (images, ...)
                                    </li>
                                    <li>
                                        <code>controllers/</code>: contains all the helper functions to use for each api route
                                    </li>
                                    <li>
                                        <code>routes/</code>: contains the api routes
                                    </li>
                                    <li>
                                        <code>routes/index.js</code>: defines the apiRouter
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
