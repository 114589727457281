import React, { useEffect, useState } from 'react';
import { niceSend, usePrefetch, useSave } from '../../utils';

import SelectData from '../SelectData';

export const defaultFields = {
    id: 0,
    name: "",
    video: "",
    image: "",
    industry: "0",
    description: ""
};

const BasicData = ({ data }) => {
    const [state, setState] = useState(defaultFields);
    const [sectors,] = usePrefetch('/api/sectors');
    const [selectedSectors, setSelectedSectors] = useState({});
    const [jobSector, setJobSector] = useState([]);

    const updateData = async () => {
        const { error } = await niceSend(
            '/api/jobs',
            'POST',
            { ...state, sectors: selectedSectors }
        );
        return { error };
    };
    const [trigger,] = useSave(updateData);

    useEffect(() => {
        setState({...state, ...data});
        setJobSector(data.sectors || []);
    }, [data]);

    const onChange = e => setState({
        ...state,
        [e.target.name]: e.target.value
    });

    return (
        <div className="card card-info">
            <div className="card-header">
                <h3 className="card-title">Basic Data</h3>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="jobName">Name</label>
                    <input type="text" className="form-control" id="jobName"
                        name="name" value={state.name} onChange={onChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="jobVideo">Video Link</label>
                    <input type="text" className="form-control" id="jobVideo"
                        name="video" value={state.video} onChange={onChange} />
                </div>
                <div className="form-group">
                    <label>Industrial Job</label>
                    <select className="form-control" name="industry" value={state.industry} onChange={onChange}>
                        <option value={0}>NO</option>
                        <option value={1}>YES</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="jobDescription">Description</label>
                    <textarea rows="5" className="form-control" id="jobDescription"
                        name="description" value={state.description} onChange={onChange} />
                </div>

                <SelectData 
                    title="Associated sector"
                    selectText="Select associated sectors"
                    allData={sectors}
                    initialState={jobSector}
                    callback={(obj) => setSelectedSectors(obj)}
                />
            </div>
            <div className="card-footer">
                <button type="submit" className="btn btn-info" onClick={() => trigger()}>Save</button>
            </div>
        </div>

    )
}

export default BasicData;
