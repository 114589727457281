import React from 'react';
import ContentHeader from "../components/ContentHeader";


export const Loader = () => {
    return (
        <div className="content-wrapper">
            <ContentHeader header="" subtitle="" routes={[]} />
            <div className="d-flex justify-content-center align-items-center" style={{ height: "30rem" }}>
                <i className="fas fa-spinner fa-pulse" style={{ fontSize: "4.5em" }}></i>
            </div>
        </div>
    );
};
