import React from 'react';
import { Link } from 'react-router-dom';

const ContentHeader = ({ header, subtitle, routes }) => {

    return (
        <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>
                            {header}&nbsp;
                            <small className="text-primary">{subtitle}</small>
                        </h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fa fa-dashboard"></i> Home</Link>
                        </li>
                        {routes.map(({ name, link }, i) => (
                            link ? (
                                <li key={i} className="breadcrumb-item"><Link to={link}>{name}</Link></li>
                            ) : (
                                    <li key={i} className="breadcrumb-item active">{name}</li>
                                )
                        ))}
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default ContentHeader;