import React, { useEffect, useState } from 'react';
import ContentHeader from "../components/ContentHeader";
import Table from '../components/Table';
import { niceSend, usePrefetch } from '../utils';

export const Users = (props) => {
    const routes = [{ name: "Users" }];
    const [basicData, setBasicData] = useState([]);
    const [data, updateData] = usePrefetch('/api/users');

    useEffect(() => {
        setBasicData(data);
    }, [data]);

    const editItem = i => props.history.push(`/edituser?id=${i}`);
    const deleteItem = async (id) => {
        try {
            const { success } = await niceSend(`/api/users/${id}`, "DELETE");
            updateData();
            return success;
        } catch (error) {
            return false;
        }
    };

    return (
        <div className="content-wrapper">
            <ContentHeader header="Users" routes={routes} />
            <Table
                header="Basic users info"
                tableName="Users"
                data={basicData}
                editItem={editItem}
                deleteItem={deleteItem}
                footer=""
            />
        </div>
    )
};