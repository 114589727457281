import React from 'react';
import { DashBoxes } from "../components/Box";
import ContentHeader from "../components/ContentHeader";
import { usePrefetch } from '../utils';

export const Dashboard = () => {
    const routes = [{name: "Dashboard"}];
    const [data,] = usePrefetch('/api/admin/dashboard');

    return (
        <div className="content-wrapper">
            <ContentHeader header="Dashboard" routes={routes} />
            <DashBoxes count={data} />
        </div>
    );
};
