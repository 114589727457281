import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import * as _ from 'lodash';
import { usePrefetch } from '../utils';
import ContentHeader from '../components/ContentHeader';
import BasicData, { defaultFields } from '../components/Users/BasicData';
import Images from '../components/Images';

export const EditUser = (props) => {
    const routes = [{name: "Users", link: "/users"}, {name: "EditUser"}];
	const id = Number(queryString.parse(props.location.search).id);
    const [basic, setBasic] = useState({});
    const [data,] = usePrefetch(`/api/users/${id}`);
    const [images, updateImages] = usePrefetch('/api/images/ambassadors');
    
    useEffect(() => {
        setBasic(_.isEmpty(data) ? defaultFields: data);
    }, [data]);

    const header = (basic.id === 0 ? "Add New" : "Edit") + " User ";

    return (
        <div className="content-wrapper">
            <ContentHeader header={header} subtitle={basic.name} routes={routes} />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <BasicData data={basic} images={images}/>
                        </div>
                        <div className="col-sm-6">
                            <Images data={images} path="avatar" updateCallback={updateImages} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
