export const serverStructure = [
    {
        type: "folder",
        name: "server",
        childrens: [
            { type: "folder", name: "controllers", childrens: []},
            { type: "folder", name: "ressources", childrens: []},
            {
                type: "folder",
                name: "routes",
                childrens: [
                    { type: "file", name: "index.js" },
                ]
            },
            { type: "file", name: "config.js" },
            { type: "file", name: "db.js" },
            { type: "file", name: "knexfile.js" },
            { type: "file", name: "server.js" },
            { type: "file", name: "utils.js" },
            { type: "file", name: "package.json" }
        ]
    },
];