import React, { useState } from 'react';
import * as _ from "lodash";
import { niceSend, useSave, usePrefetch } from '../../utils';
import SelectData from '../SelectData';

const OtherData = ({ data, id }) => {
    const [products,] = usePrefetch("/api/products");
    const [sectors,] = usePrefetch("/api/sectors");
    const [selectedSectors, setSelectedSectors] = useState({});
    const [selectedProducts, setSelectedProducts] = useState({});

    const [trigger,] = useSave(async () => {
        const apiData = {
            products: selectedProducts,
            sectors: selectedSectors,
        };
        const res = await niceSend(`/api/ambassadors/${id}`, 'POST', apiData);
        return res;
    });

    const callback = (selectedData, key) => {
        if (key === "products") {
            setSelectedProducts(selectedData);
        } else if (key === "sectors") {
            setSelectedSectors(selectedData);
        }
    };

    return (
        <div className="card card-success">
            <div className="card-header">
                <h3 className="card-title">Ambassador Products</h3>
            </div>
            <div className="card-body">
                <SelectData
                    title="Update products"
                    selectText="Select one or more items"
                    allData={products}
                    initialState={data.products || []}
                    callback={(data) => callback(data, "products")}
                />
                <SelectData
                    title="Update sectors"
                    selectText="Select one or more items"
                    allData={sectors}
                    initialState={data.sectors || []}
                    callback={(data) => callback(data, "sectors")}
                />

            </div>
            <div className="card-footer">
                <button type="submit" className="btn btn-success" onClick={() => trigger()}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default OtherData;
