import Swal from "sweetalert2";
import { niceSend } from "./utils";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-start',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const makeToast = (icon, title) => {
    Toast.fire({ icon, title })
};

export const newUserAdded = (email, pwd, accessStatus) => {
    Swal.fire({
        title: '<strong>New user added !</strong>',
        icon: 'info',
        html:
            "You've created a new user: <br> <b>Email:</b> " + email +
            '<br><b>Password:</b> ' + pwd +
            '<br><b>Status:</b> ' + accessStatus + '.' +
            '<br>You can now send these credentials to this user.' +
            '<br><br><u>Please do not close this window before saving the password or sending it to the user</u>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> Great!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText:
            '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
    });
};

const swalViewImage = Swal.mixin({
    buttonsStyling: true
});

export const viewImage = (path, name, updateCallback) => {
    const realPath = 'ressources/' + path;

    swalViewImage.fire({
        title: name,
        text: `Image from /${realPath}/`,
        imageUrl: `/${realPath}/${name}`,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: 'Custom image',
        showDenyButton: true,
        denyButtonText: 'Edit image name',
    }).then(result => {
        if (result.isDenied) {
            swalViewImage.fire({
                title: 'Submit new file name',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                preConfirm: async (newName) => {
                    const type = '.' + name.split('.').slice(-1)[0];
                    if (!newName.includes('.')) {
                        newName = newName.split('.')[0] + type;
                    }
                    const data = { path, name, newName };
                    const { error } = await niceSend('/api/images/rename', 'POST', data);
                    if (error) {
                        swalViewImage.showValidationMessage(
                            `Request failed: ${error}`
                        );
                    } else return newName;
                },
                allowOutsideClick: () => !swalViewImage.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    swalViewImage.fire({
                        title: result.value,
                        text: `Image from /${realPath}/`,
                        imageUrl: `/${realPath}/${result.value}`,
                        imageWidth: 200,
                        imageHeight: 200,
                        imageAlt: 'Custom image',
                    });
                    if (updateCallback) updateCallback();
                }
            })
        }
    });
};

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
    },
    buttonsStyling: true
});

export const DeleteButton = (props) => {

    const confirmDelete = () => {
        swalWithBootstrapButtons.fire({
            title: `Deleting item "${props.name}" from ${props.tableName}. Are you sure?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const done = await props.deleteItem(props.id || props.name);
                swalWithBootstrapButtons.fire(
                    done ? 'Deleted!' : 'Error occured',
                    done ? 'Your item has been deleted.' : "Can't delete this file",
                    done ? 'success' : 'error'
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Your item is safe :)',
                    'error'
                );
            }
        });
    };

    return (
        <button className="btn btn-danger" onClick={confirmDelete}>
            <i className="fa fa-trash "></i>
        </button>
    );
}
